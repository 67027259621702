export default {
  formTitle: "Kredit ohne Einkommensnachweis",
  formSubTitle: "Auszahlung in 5 Min.",
  heading: "Sofort-Kredit in 5 Min.",
  subHeading: "Sofortkredit ohne Einkommensnachweis, ohne Gehaltsnachweis.",
  notice: `Bei einem Kredit ohne Einkommensnachweis verzichtet der Kreditgeber auf die Überprüfung von Einkommensnachweisen, Gehaltszetteln oder Lohnnachweisen und vergibt den Kredit ohne Nachweise. Normalerweise ist es üblich, dass Kreditgeber – meist Banken – die Bonität des Kreditnehmers über Gehaltsnachweise oder Einkommensnachweise zusätzlich überprüfen. Zusätzlich bedeutet, dass neben persönlichen Angaben, Angaben zum Einkommen und zu den Ausgaben und Informationen von der Schufa auch noch Nachweise benötigt werden.`,
  itemsTitle: "Ihre Vorteile in der Übersicht:",
  items: [
	"Für Selbständige, Studenten und Personen ohne Gehaltsnachweis",
	"Antrag ohne Einfluss auf Schufa",
	"Geld von privaten Anlegern",
	"Unbürokratisch, schnell und einfach",
    "Angebotserstellung ist für Sie 100% kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
    "Die Antragsprüfung undEntscheidung erfolgt sofort online!",
    "Zeitraubende Bankbesuche entfallen",
    "Sofortige Antragsannahme ist garantiert!",
    "Schnell, diskret und einfach",
    "Betrag meistens innerhalb von 24 Stunden verfügbar.",
    "Machen Sie mit Ihrem Geld was Sie wollen! (Keine Zweckbindung der Kredite)",
    "Unser freundlicher Kundenservice berät Sie professionell bei allen Anliegen",
    "Laufzeiten flexibel wählbar",
    "Individuell nach Ihrem Wunsch: Zinssatz fest oder flexibel während der Laufzeit",
    "Ohne aufwändige Vertreterbesuche, alles wird auf dem Postweg erledigt"
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
